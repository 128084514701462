// el-pagination 分页器的二次封装

<template>
  <div class="pagination">
    <el-pagination
      background
      :current-page="pageNo"
      :page-size="pageSize"
      :total="total"
      :page-sizes="[10, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageNo: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('update:pageSize', val)
      this.$emit('change')
    },
    handleCurrentChange(val) {
      this.$emit('update:pageNo', val)
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  text-align: right;
  padding-top: 20px;
}
</style>
